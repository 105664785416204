﻿window.SiteLocation = {
    city: null,
    country: null,
    continent: null,
    dataKey: 'locations-',
    xhr: null,

    add: function (item, name) {
        this.remove(item, name);
        var ls = SiteData.getObj(this.dataKey + name) || [];
        ls.unshift(item);
        this.save(ls, name);
    },

    list: function (name) {
        var all = SiteData.getObj(this.dataKey + name) || [];
        console.log(all)
        if (name === 'favs' && all.length === 0) all.push(SiteLocation.cityDefault);
        return all.slice(0, 50);
    },

    remove: function (item, name) {
        var ls = SiteData.getObj(this.dataKey + name) || [];
        var newLs = [];
        for (var i = 0; i < ls.length; i++) {
            var listItem = ls[i];
            if (listItem.GeoId !== item.GeoId) newLs.push(this);
        }
        this.save(newLs, name);
    },

    save: function (locations, name) {
        SiteData.setObj(this.dataKey + name, locations);
    },

    search: function (query, onSuccess, index) {
        if (SiteLocation.xhr !== null) {
            SiteLocation.xhr.abort();
        }

        if (query.length >= 3) {
            SiteLocation.xhr = new XMLHttpRequest();

            SiteLocation.xhr.onreadystatechange  = function () {
                if (this.readyState === 4 && this.status === 200) {
                    var data = JSON.parse(this.responseText);
                    onSuccess(data, query, index);
                }
            };

            SiteLocation.xhr.open('GET', 'api/geo/search/?query=' + query, true);
            SiteLocation.xhr.setRequestHeader('Content-Type', 'application/json');
            SiteLocation.xhr.send();
        } else {
            onSuccess(null, query, index);
        }
    }
};