﻿window.SiteData = {
    prefix: 'wvs-',

    get: function (key) {
        return localStorage.getItem(this.prefix + key);
    },

    getObj: function (key) {
        var val = this.get(key);
        return val === null ? null : JSON.parse(val);
    },

    set: function (key, val) {
        if (val === null) {
            localStorage.removeItem(this.prefix + key);
        } else {
            localStorage.setItem(this.prefix + key, val);
        }
    },

    setObj: function (key, val) {
        this.set(key, JSON.stringify(val));
    },

    dec2hex: function (dec) {
        return dec.toString(16).padStart(2, "0");
    },

    generateId: function(len) {
        const arr = new Uint8Array((len || 40) / 2);
        window.crypto.getRandomValues(arr);
        return Array.from(arr, this.dec2hex).join('');
    }
};