﻿import "../libs/hammer.min";

import "../libs/ip/weatherMaps/ipw-tiny.min"

import "./Site.Data";
import "./Site.Location";
import "./Site.Modal";

window.isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
}

window.Site = {
    isMobile: () => window.innerWidth <= 768,
    isTablet: () => window.innerWidth > 768 && window.innerWidth <= 1024,
    isDesktop: () => window.innerWidth >= 1025
}

window.sanitize = (txt) => {
    if (txt) {
        txt = txt.toString();
        txt = txt.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
    }
    return txt;
}

window.loadStyle = (src) => {
    const link = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = src;
    document.head.appendChild(link);
}

window.loadScript = (src, onLoad) => {
    const script = document.createElement('script');
    if (onLoad) script.onload = onLoad;
    script.src = src;
    document.head.appendChild(script);
}

window.httpGet = (url, onSuccess) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            const data = JSON.parse(this.responseText);
            onSuccess(data);
        }
    };

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
    return xhr;
}