﻿window.SiteModal = {
    init: function () {
        window.addEventListener('resize', function () {
            SiteModal.resize();
        });
    },

    create: function (title, body, modalDialogWidth, modalBodyHeight) {
        var modalId = 'modal-' + new Date().getTime();

        var modalOnHide = function () {
            SiteModal.hide(modalId);
        };

        // create modal element and add to body
        var modalEl = document.createElement('div');
        modalEl.className = 'modal';
        modalEl.classList.add('removable');
        modalEl.classList.add('modal-image');
        modalEl.id = modalId;
        document.querySelector('body').appendChild(modalEl);

        // create modal overlay
        var modalOverlay = document.createElement('div');
        modalOverlay.className = 'modal-overlay';
        modalOverlay.addEventListener('click', function () {
            modalOnHide();
        });
        modalEl.appendChild(modalOverlay);

        // create modal dialog
        var modalDialog = document.createElement('div');
        modalDialog.className = 'modal-dialog';
        modalEl.appendChild(modalDialog);

        if (modalDialogWidth !== null && modalDialogWidth !== undefined) {
            modalDialog.style.width = modalDialogWidth + "px";
        }

        // create modal header
        var modalDialogHeader = document.createElement('div');
        modalDialogHeader.className = 'modal-header';
        modalDialogHeader.classList.add('clearfix');
        modalDialog.appendChild(modalDialogHeader);

        // create modal header close button
        var modalDialogHeaderBtnClose = document.createElement('button');
        modalDialogHeaderBtnClose.addEventListener('click', function () {
            modalOnHide();
        });
        modalDialogHeader.appendChild(modalDialogHeaderBtnClose);

        // create modal header close button image
        var closeBtn = document.createElement('img');
        closeBtn.className = 'close';
        closeBtn.alt = 'sluit modal';
        closeBtn.src = 'https://assets.infoplaza.io/site/weersvoorspelling.nl/icons/awesome/close.svg';
        modalDialogHeaderBtnClose.appendChild(closeBtn);

        //create modal header title
        var modalDialogHeaderTitle = document.createElement('h4');
        modalDialogHeaderTitle.className = 'title';
        modalDialogHeaderTitle.innerText = title;
        modalDialogHeader.appendChild(modalDialogHeaderTitle);

        // create modal body
        var modalDialogBody = document.createElement('div');
        modalDialogBody.className = 'modal-body';
        modalDialogBody.addEventListener('click', function () {
            modalOnHide();
        });
        modalDialog.appendChild(modalDialogBody);

        if (modalBodyHeight !== null && modalBodyHeight !== undefined) {
            modalDialogBody.style.height = modalBodyHeight + "px";
        }

        modalDialogBody.appendChild(body);

        SiteModal.show(modalId);
    },

    createIframeModal: function (url, title) {
        var iframe = document.createElement('iframe');
        iframe.frameBorder = 0;
        iframe.scrolling = "no";
        iframe.src = url;
        iframe.style.height = "330px";
        iframe.style.width = "360px";
        SiteModal.create(title, iframe, 396, 340);
    },

    createImageModal: function(url, caption) {
        // fix url;
        url = url.replace('url("', '').replace('")', '');

        var div = document.createElement('div');

        var imageEl = document.createElement('img');
        imageEl.src = url;
        imageEl.addEventListener('click', function () {
            modalOnHide();
        });        

        // create caption div
        var modalCaption = document.createElement('div');
        modalCaption.className = 'caption';
        modalCaption.innerText = caption;

        // append last things
        div.appendChild(imageEl);
        div.appendChild(modalCaption);

        SiteModal.create('', div, null, null);
    },

    hide: function (id) {
        var element = document.getElementById(id);
        if (element.classList.contains('removable')) {
            element.parentNode.removeChild(element);
        } else {
            element.style.display = 'none';
        }
    },

    show: function (id) {
        var element = document.getElementById(id);
        element.style.display = 'block';
        SiteModal.resize();
    },

    resize: function () {
        var modals = document.querySelectorAll('.modal');

        for (var i = 0; i < modals.length; i++) {
            //  add resize code
        }
    }
};

SiteModal.init();